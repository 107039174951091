import { getEnv } from './utils/env.server.ts'

const ENV = global.ENV ?? getEnv()

export default {
	title: 'More Than Code',
	description: 'Blog by @knownasilya about life and web development.',
	domainName:
		ENV.MODE === 'production'
			? 'https://ilyaradchenko.com'
			: 'http://localhost:3000',
	email: 'knownasilya@gmail.com',
}
